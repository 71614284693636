import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We have lived a hurtful history`}<br parentName="p"></br>{`
`}{`through the centuries which have passed,`}<br parentName="p"></br>{`
`}{`but I have yet to understand`}<br parentName="p"></br>{`
`}{`why it has not still been surpassed.`}</p>
    <p>{`Why must prejudices be created`}<br parentName="p"></br>{`
`}{`from the simple vision of one’s gender,`}<br parentName="p"></br>{`
`}{`for there is no solidified set of guidelines`}<br parentName="p"></br>{`
`}{`for what one might simply offer or render.`}</p>
    <p>{`Have we dismissed equity?`}</p>
    <p>{`Stereotypes are continuously created`}<br parentName="p"></br>{`
`}{`even through attempts to alter the tormented present.`}<br parentName="p"></br>{`
`}{`Feminism is being misjudged, misinterpreted,`}<br parentName="p"></br>{`
`}{`but it’s solely a way to express the discontent.`}</p>
    <p>{`You will not be considered an individual`}<br parentName="p"></br>{`
`}{`who hates certain cultures, loathes marriage and men,`}<br parentName="p"></br>{`
`}{`but rather someone who wants to ensure`}<br parentName="p"></br>{`
`}{`the presence of equity among genders once again.`}</p>
    <p>{`Have we dismissed equity?`}</p>
    <p>{`Women’s abilities are being belittled and undermined.`}<br parentName="p"></br>{`
`}{`Women should not have to encounter futile fright,`}<br parentName="p"></br>{`
`}{`in seeming more powerful, educated and experienced.`}<br parentName="p"></br>{`
`}{`Feminism stands up for what is truly right.`}</p>
    <p>{`A gender should not be forced to hide themselves,`}<br parentName="p"></br>{`
`}{`their abilities and strengths because of being intimidating.`}<br parentName="p"></br>{`
`}{`Lack of female leadership, jobs and opportunities occur`}<br parentName="p"></br>{`
`}{`because men are expected to be the ones dominating.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      